import * as React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';

import { Colors } from 'common/src/constants';
import useWindowDimensions from 'common/src/hooks/useWindowDimensions';

interface IProps {
  label: string;
  onSearchTermChange: (newSearchTerm: string) => void;
}
const SearchBar: React.FC<IProps> = ({ label, onSearchTermChange }) => {
  // inputTerm shows in the search bar, searchTerm is the term that is actually used
  // for filtering the event templates. This is normally the same, except when IME (e.g. chinese
  // input) is used.
  const [inputTerm, setInputTerm] = React.useState('');
  const [isComposing, setIsComposing] = React.useState(false);

  const { windowWidth } = useWindowDimensions();

  return (
    <TextField
      size='small'
      sx={{
        width: windowWidth < 500 ? undefined : 300,
        mt: { sm: 0, md: 10 },
        mb: { sm: 20, md: 30 },
      }}
      label={label}
      fullWidth={windowWidth < 500}
      InputLabelProps={{ sx: { fontSize: 14 } }}
      InputProps={{
        endAdornment: <SearchIcon fontSize='small' sx={{ color: Colors.TEXT_SYSTEM_LIGHT }} />,
      }}
      value={inputTerm}
      onChange={(event) => {
        const newInputTerm = event.target.value;
        setInputTerm(newInputTerm);
        if (!isComposing) {
          onSearchTermChange(newInputTerm.trim().toLowerCase());
        }
      }}
      onCompositionStart={() => setIsComposing(true)}
      onCompositionEnd={() => {
        setIsComposing(false);
        onSearchTermChange(inputTerm.trim().toLowerCase());
      }}
    />
  );
};

export default SearchBar;
