import { Gender, Location } from './user';

export interface RegistrationData {
  phoneToken: string;
  email: string;
  name?: string;
  gender?: Gender;
  dob?: string;
  location?: Location;
}

export enum RegistrationStatus {
  OK = 'ok',
  INVALID = 'invalid',
}

export enum GetPersistentTokenErrCode {
  UNASSOCIATED_PRINCIPAL = 'unassociated_principal'
}
