import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import { useTranslation } from 'react-i18next';
import { Box, TextField } from '@mui/material';

import { Location } from 'common/src/models/user';
import Logger from 'common/src/utils/logger';


const LOGGER = Logger.getInstance();

interface IProps {
  location: Location;
  onChangeLocation: (newLocation: Location) => void;
}

const CitySelector: React.FC<IProps> = ({ location, onChangeLocation }) => {
  const { t: tProfile } = useTranslation('wbevt.profile');

  const [isFocused, setIsFocused] = React.useState(false);
  const [query, setQuery] = React.useState('');

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: 'AIzaSyBAt5SOlYts6igsIEahCrHdridiwukiUnQ',
    onPlaceSelected: (dat) => {
      const loc = locationFromGeodata(dat);
      onChangeLocation({
        ...location,
        city: loc.city,
        state: loc.state,
        latlng: loc.latlng,
        zipcode: loc.zipcode,
      });

      setQuery('');
    },
    options: {
      types: ['(cities)'], // Restrict predictions to cities
      componentRestrictions: { country: ['us', 'ca'] },
    },
  });

  let cityState = '';
  if (location.city) {
    cityState = location.city;
    if (location.state) {
      cityState += `, ${location.state}`;
    }
  }

  return (
    <Box >
      <TextField
        inputRef={ref}
        margin='normal'
        fullWidth
        label={tProfile('profile.city')}
        placeholder=''
        value={isFocused ? query : cityState}
        onChange={(event) => setQuery(event.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        autoComplete='off'
      />
    </Box>
  );
};


function locationFromGeodata(
  data: any,
): Location {
  try {
    const city = data.address_components.filter((t: any) =>
      t.types.includes('locality'),
    )[0]?.long_name;
    const state = data.address_components.filter((t: any) =>
      t.types.includes('administrative_area_level_1'),
    )[0]?.short_name;
    const zipcode = data.address_components.filter((t: any) =>
      t.types.includes('postal_code'),
    )[0]?.long_name;
    const latlng = data.geometry.location.lat() + ',' + data.geometry.location.lng();

    const streetNumber = data.address_components.filter((t: any) =>
      t.types.includes('street_number'),
    )[0]?.long_name;
    const address = data.address_components.filter((t: any) =>
      t.types.includes('route'),
    )[0]?.short_name;
    const unit = data.address_components.filter((t: any) =>
      t.types.includes('subpremise'),
    )[0]?.short_name;

    return {
      longlat: '',
      latlng: latlng,
      address: ((streetNumber || '') + (address ? (' ' + address) : '') + (unit ? (', ' + unit) : '')) .trim(),
      city: city || '',
      zipcode: zipcode || '',
      state: state || '',
    };
  } catch (e) {
    LOGGER.error('Failed to parse location from geodata. Payload: ' + JSON.stringify(data) +
      ' Error: ' + JSON.stringify(e));
    throw e;
  }
}

export default CitySelector;
