const CHARACTERS = 'abcdefghijklmnopqrstuvwxyz0123456789';

export function encodeNumberToLetters(numStr: string): string {
  const base = CHARACTERS.length;
  let num = BigInt(numStr); // Convert the number to a BigInt for handling large values
  let encoded = '';

  while (num > 0) {
    const remainder = Number(num % BigInt(base)); // Get the remainder
    encoded = CHARACTERS[remainder] + encoded; // Map remainder to a letter
    num = num / BigInt(base); // Reduce the number
  }

  return encoded;
}

export function decodeLettersToNumber(encoded: string): string {
  const base = CHARACTERS.length;
  let number = BigInt(0);

  for (const char of encoded.split('')) {
    const value = BigInt(CHARACTERS.indexOf(char)); // Get the numerical value of the letter
    number = number * BigInt(base) + value; // Reconstruct the number
  }

  return number.toString();
}
