import * as React from 'react';
import { Alert, Snackbar } from '@mui/material';

interface ISpinner {
  type?: 'saving' | 'loading' | 'modifying' | 'uploading';
  msg?: string;
}

interface INotifierContext {
  // Spinner
  spinnerMsg: string;
  setSpinner: (spinner: ISpinner) => void;
  clearSpinner: () => void;

  // Snackbar
  showSnackbar: (msg: string, severity?: 'success' | 'info' | 'warning' | 'error') => void;
}

const NotifierContext = React.createContext<INotifierContext>({
  spinnerMsg: '',
  setSpinner: () => { },
  clearSpinner: () => { },
  showSnackbar: () => { },
});

export { NotifierContext };

interface IProps {
  children: React.ReactNode;
}
const NotifierProvider: React.FC<IProps> = ({ children }) => {
  const [spinnerMsg, setSpinnerMsg] = React.useState<string>('');

  const setSpinner = React.useCallback((spinner: ISpinner) => {
    let msg = spinner.msg;
    if (!msg) {
      switch (spinner.type) {
        case 'loading':
          msg = 'Loading';
          break;
        case 'saving':
          msg = 'Saving';
          break;
        case 'modifying':
          msg = 'Modifying';
          break;
        case 'uploading':
          msg = 'Uploading';
          break;
      }
    }

    setSpinnerMsg(msg || '');
  }, []);

  const clearSpinner = React.useCallback(() => {
    setSpinnerMsg('');
  }, []);

  const [snackbarMsg, setSnackbarMsg] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState<'success' | 'info' | 'warning' | 'error'>('info');

  const showSnackbar = React.useCallback((msg: string, severity: 'success' | 'info' | 'warning' | 'error' = 'info') => {
    setSnackbarMsg(msg);
    setSnackbarSeverity(severity);
  }, []);

  const clearSnackbar = React.useCallback(() => {
    setSnackbarMsg('');
  }, []);

  return (
    <NotifierContext.Provider value={{
      spinnerMsg, setSpinner, clearSpinner, showSnackbar,
    }}>
      {children}
      <Snackbar
        open={!!snackbarMsg}
        autoHideDuration={4500}
        onClose={clearSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={clearSnackbar} severity={snackbarSeverity}>
          {snackbarMsg}
        </Alert>
      </Snackbar>
    </NotifierContext.Provider>
  );
};
export default NotifierProvider;
