import React from 'react';
import { Route, Routes } from 'react-router-dom';

import NotFoundScreen from 'common/src/screens/misc/NotFoundScreen';
import PhoneVerificationScreen from '../screens/auth/PhoneVerificationScreen';
import RegistrationScreen from '../screens/auth/RegistrationScreen';
import EventDetailsScreen from '../screens/events/EventDetailsScreen';
import EventGroupScreen from '../screens/events/EventGroupScreen';
import MyEventsScreen from '../screens/events/MyEventsScreen';
import OrderConfirmationScreen from '../screens/events/OrderConfirmationScreen';
import OrderPaymentScreen from '../screens/events/OrderPaymentScreen';
import TicketSelectionScreen from '../screens/events/TicketSelectionScreen';
import ViewOrderDetailsScreen from '../screens/events/ViewOrderDetailsScreen';
import HomeScreen from '../screens/HomeScreen';
import ProfileScreen from '../screens/ProfileScreen';
import TaroPassScreen from '../screens/TaroPassScreen';

export default function RootNavigator() {
  return (
    <Routes>
      <Route path='/' element={<HomeScreen />} />
      <Route path='/auth' element={<PhoneVerificationScreen />} />
      <Route path='/register' element={<RegistrationScreen />} />
      <Route path='/eg/:eventTemplateGroupId' element={<EventGroupScreen />} />
      <Route path='/e/:eventTemplateId' element={<EventDetailsScreen />} />
      <Route path='/e/:eventTemplateId/r' element={<TicketSelectionScreen />} />
      <Route path='/p/cart' element={<OrderConfirmationScreen />} />
      <Route path='/p/payment' element={<OrderPaymentScreen />} />
      <Route path='/t/:ticketOrderId' element={<ViewOrderDetailsScreen />} />
      <Route path='/profile' element={<ProfileScreen />} />
      <Route path='/myevents' element={<MyEventsScreen />} />
      <Route path='/taropass' element={<TaroPassScreen />} />
      <Route path='*' element={<NotFoundScreen />} />
    </Routes>
  );
}
