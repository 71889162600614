
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, FormControlLabel, Radio, RadioGroup, Stack, TextField } from '@mui/material';
import * as Yup from 'yup';

import { Colors } from 'common/src/constants';
import { AuthContext } from 'common/src/context/auth';
import { NotifierContext } from 'common/src/context/notifier';
import { RegistrationStatus } from 'common/src/models/registration';
import { Gender, Location } from 'common/src/models/user';
import { decodeLettersToNumber } from 'common/src/utils/encode';
import { DefaultStyleAttrs } from '../../constants/styles';

import { Page, Text } from 'common/src/components/base';
import CitySelector from '../../components/CitySelector';
import MainBackground from '../../components/MainBackground';
import TopMenu from '../../components/TopMenu';

const emailSchema = Yup.string().email();

export default function RegistrationScreen() {
  const notifier = React.useContext(NotifierContext);

  const { t: tProfile } = useTranslation('wbevt.profile');
  const { t: tMisc } = useTranslation('wbevt.misc');

  const { registerUser } = React.useContext(AuthContext);

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const phone = `+1${decodeLettersToNumber(query.get('p') || '')}`;
  const phoneToken = query.get('tok') || '';

  const [saving, setSaving] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState('');

  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [gender, setGender] = React.useState<Gender|null>(null);
  const [location, setLocation] = React.useState<Location>( { address: '', city: '', state: '', zipcode: '', latlng: '', longlat: '' } );

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!phoneToken) {
      navigate('/auth');
    }
  }, [phoneToken, navigate]);

  const handleRegister = React.useCallback(async () => {
    // Validation
    try {
      emailSchema.validateSync(email);
    } catch (error) {
      setErrMsg('Email is invalid');
      return;
    }

    // Save
    setSaving(true);

    const registrationStatus = await registerUser({ phoneToken, email, name, gender: gender || undefined, location });
    if (registrationStatus !== RegistrationStatus.OK) {
      setErrMsg('Failed to register. Please contact support@hellotaro.com');
      return;
    }

    setSaving(false);

    notifier.showSnackbar(tMisc('auth.registrationSuccessful'), 'success');
    navigate('/');
  }, [registerUser, phoneToken, email, name, gender, location, notifier, tMisc, navigate]);


  return (
    <Page centered>
      <MainBackground />
      <TopMenu addMarginX />

      <Box
        display='flex' position='relative' justifyContent='center'
        mt={30} mx={DefaultStyleAttrs.responsiveMx}
      >
        <Box
          p={40} maxWidth={600}
          bgcolor={Colors.WHITE} border={1} borderRadius={5} borderColor={Colors.DISABLED}
        >
          <Text size='banner' variant='bold' mb={20}>{tProfile('profile.title')}</Text>

          <TextField
            margin='normal'
            required
            fullWidth
            label={tProfile('profile.phone')}
            value={phone}
            disabled
          />

          <TextField
            margin='normal'
            required
            fullWidth
            label={tProfile('profile.email')}
            autoComplete='email'
            value={email}
            onChange={(event) => {
              const newEmail = event.target.value;
              setEmail(newEmail.trim());
              setErrMsg('');
            }}
            placeholder='taro@gmail.com'
          />

          <TextField
            margin='normal'
            fullWidth
            label={tProfile('profile.username')}
            autoComplete='name'
            value={name}
            onChange={(event) => {
              const newName = event.target.value;
              setName(newName);
            }}
          />

          <Stack direction='row' alignItems='center' border={1} borderColor='#c4c4c4' borderRadius={1} py={5} px={12} mt={14}>
            <Text color={Colors.GREY700} mr={30} sx={{ fontSize: 16 }}>{tProfile('profile.gender')}</Text>
            <RadioGroup row value={gender} onChange={(event) => setGender(event.target.value as Gender)}>
              <FormControlLabel value='female' control={<Radio />} label={tProfile('profile.genderFemale')} sx={{ color: Colors.GREY700 }} />
              <FormControlLabel value='male' control={<Radio />} label={tProfile('profile.genderMale')} sx={{ color: Colors.GREY700 }} />
            </RadioGroup>
          </Stack>

          <CitySelector location={location} onChangeLocation={setLocation} />

          <Stack direction='column' display='flex' alignItems='center' mt={50}>
            {errMsg ? <Text size='paragraph' color='error' mb={5}>{errMsg}</Text> : null}
            <Button
              size='large' variant='contained' sx={{ borderRadius: DefaultStyleAttrs.borderRadius, px: 60 }}
              disabled={saving || !email}
              onClick={handleRegister}
            >
              {tMisc('auth.register')}
              {saving ? <CircularProgress size={15} sx={{ position: 'absolute', right: 35 }}/> : null}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Page>
  );
}
