export interface IProps {
  color: string
}

export default function MyEventsSvg({ color }: IProps) {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path d='M6.69605 3.72314L6.14613 3.85005C6.19593 4.06679 6.20216 4.29126 6.16444 4.51042C6.12672 4.72959 6.04581 4.93907 5.92642 5.12669C5.80702 5.3143 5.65152 5.47632 5.46896 5.60331C5.28639 5.7303 5.08041 5.81973 4.86297 5.86641C4.42207 5.95915 3.96237 5.87522 3.58268 5.63265C3.203 5.39007 2.93364 5.00822 2.83249 4.56917L1.73265 4.79478C1.58182 4.82769 1.43923 4.89083 1.31349 4.9804C1.18775 5.06996 1.08147 5.18409 1.00108 5.31588C0.920682 5.44767 0.867841 5.59439 0.845744 5.74718C0.823648 5.89997 0.832755 6.05565 0.872516 6.20482C0.872516 6.20482 1.38014 8.55959 1.97236 11.2105' stroke={color} strokeWidth='1.2' strokeLinecap='round' strokeLinejoin='round'/>
      <path d='M17.2708 3.70893C17.1865 3.94635 17.0549 4.1642 16.884 4.34927C16.7131 4.53434 16.5063 4.68278 16.2763 4.7856C16.0463 4.88843 15.7979 4.94349 15.546 4.94745C15.2941 4.95141 15.044 4.90419 14.8109 4.80865C14.5777 4.71311 14.3664 4.57124 14.1898 4.39164C14.0131 4.21203 13.8747 3.99843 13.783 3.76377C13.6914 3.52912 13.6483 3.27829 13.6564 3.02649C13.6645 2.77469 13.7236 2.52715 13.8302 2.29888L12.6881 1.76307C12.3831 1.63597 12.0403 1.63428 11.7341 1.75835C11.4279 1.88242 11.1829 2.12225 11.0524 2.42579L6.31465 13.5792L5.69422 14.9893C5.56712 15.2942 5.56543 15.637 5.6895 15.9433C5.81357 16.2495 6.05341 16.4944 6.35695 16.6249L12.0818 19.0643C12.3868 19.1914 12.7296 19.1931 13.0358 19.069C13.342 18.9449 13.5869 18.7051 13.7174 18.4016L19.0757 5.79579C19.1938 5.50265 19.197 5.17574 19.0846 4.88036C18.9722 4.58497 18.7525 4.34288 18.4693 4.20244L17.2708 3.70893Z' stroke={color} strokeWidth='1.7' strokeLinecap='round' strokeLinejoin='round'/>
    </svg>
  );
}
