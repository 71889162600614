import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Stack, Tab, Tabs } from '@mui/material';

import { GeoStateCode } from 'common/src/models/event';

import GeoStateFilter from '../../components/GeoStateFilter';
import EventsTab from './EventsTab';

interface IProps { }
const MainContent: React.FC<IProps> = () => {
  const [tabIdx, setTabIdx] = React.useState(0);
  const { t } = useTranslation('wbevt.home');

  const availableStateFilters = React.useMemo(() => {
    return Object.values(GeoStateCode);
  }, []);

  const handleChangeTabIdx = (event: React.SyntheticEvent, newTab: number) => {
    setTabIdx(newTab);
  };

  return (
    <Box>
      <Stack direction='row' mb={10} justifyContent='space-between' alignItems='center'>
        <Tabs value={tabIdx} onChange={handleChangeTabIdx}>
          <Tab label={t('landing.currentEvent')} />
          <Tab label={t('landing.pastEvent')} />
        </Tabs>
        <GeoStateFilter availableStateFilters={availableStateFilters} />
      </Stack>
      <TabContent shown={tabIdx === 0}>
        {tabIdx === 0 && <EventsTab eventType='current' />}
      </TabContent>

      <TabContent shown={tabIdx === 1}>
        {tabIdx === 1 && <EventsTab eventType='past' />}
      </TabContent>
    </Box>
  );
};

interface ITabContentProps {
  shown: boolean;
  children?: React.ReactNode;
}
const TabContent: React.FC<ITabContentProps> = ({ shown, children }) => {
  return (
    <Box>
      {shown && children}
    </Box>
  );
};

export default MainContent;
