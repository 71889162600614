import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { GeoStateCode, GeoStateName } from 'common/src/models/event';
import useAppDispatch from '../hooks/useAppDispatch';
import useAppSelector from '../hooks/useAppSelector';
import { selectStateFilterWithDefault, setStateFilter } from '../redux/slices/setting';


interface IProps {
  availableStateFilters: GeoStateCode[];
}

const GeoStateFilter: React.FC<IProps> = ({ availableStateFilters }) => {
  const { t } = useTranslation('wbevt.home');
  const dispatch = useAppDispatch();

  const stateFilter = useAppSelector(selectStateFilterWithDefault);

  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'>
      <FormControl margin='normal' size='small' sx={{ width: 160 }}>
        <InputLabel sx={{ fontSize: 14, left: 6 }}>{t('landing.stateSelectorLabel')}</InputLabel>
        <Select
          required
          label={t('landing.stateSelectorLabel')}
          value={stateFilter}
          onChange={(event: SelectChangeEvent) => {
            if (!event.target.value) {
              return;
            }
            dispatch(setStateFilter(event.target.value as GeoStateCode));
          }}
        >
          {availableStateFilters.map((state) => (
            <MenuItem key={state} value={state}>{GeoStateName[state]}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default GeoStateFilter;
