import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Avatar, Box, Button, InputAdornment, Link, TextField } from '@mui/material';

import { Colors } from 'common/src/constants';
import { AuthContext, LoginResult } from 'common/src/context/auth';
import { getPhoneTokenRemote, verifyPhoneRemote } from 'common/src/system/network/identity';
import { encodeNumberToLetters } from 'common/src/utils/encode';
import { DefaultStyleAttrs } from '../../constants/styles';

import { Page, Text } from 'common/src/components/base';
import MainBackground from '../../components/MainBackground';
import TopMenu from '../../components/TopMenu';

export default function LoginScreen() {
  const [phone, setPhone] = React.useState('');
  const [smsCode, setSmsCode] = React.useState('');
  const [smsSent, setSmsSent] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState('');

  const { login } = React.useContext(AuthContext);
  const { t } = useTranslation('wbevt.misc');

  const navigate = useNavigate();

  const handleSubmit = async () => {
    if (smsSent) {
      const getPhoneTokenResp = await getPhoneTokenRemote(phone, smsCode);
      if (getPhoneTokenResp.errCode === 'invalid_principal_or_secret') {
        setErrMsg(t('auth.invalidPhoneToken'));
        return;
      }

      // Received correct phone token. Now login
      const loginResult = await login(getPhoneTokenResp.token);
      switch (loginResult) {
        case LoginResult.SUCCESS:
          navigate('/');
          break;
        case LoginResult.NOT_REGISTERED:
          navigate(`/register?tok=${getPhoneTokenResp.token}&p=${encodeNumberToLetters(phone)}`);
          break;
      }
    } else {
      await verifyPhoneRemote(phone);
      setSmsSent(true);
    }
  };

  return (
    <Page centered>
      <MainBackground />
      <TopMenu addMarginX />

      <Box
        display='flex' position='relative' justifyContent='center'
        mt={30} mx={DefaultStyleAttrs.responsiveMx}
      >
        <Box
          display='flex' flexDirection='column' alignItems='center'
          p={40} width={600}
          bgcolor={Colors.WHITE} border={1} borderRadius={5} borderColor={Colors.DISABLED}
        >
          <Avatar sx={{ m: 8, bgcolor: 'primary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Text size='title' mb={30}>{t('auth.phoneVerificationInstructions')}</Text>
          <TextField
            margin='normal'
            required
            fullWidth
            label={t('auth.phone')}
            autoComplete='phone'
            autoFocus
            value={phone}
            onChange={(event) => {
              // Remove non-numerics and ensure size<=10
              const newPhone = event.target.value.replace(/\D/g, '').substring(0, 10);
              setErrMsg('');
              setPhone(newPhone);
              if (newPhone.length < 10) {
                setSmsSent(false);
              }
            }}
            InputProps={{
              startAdornment: <InputAdornment position='start'>+1</InputAdornment>,
            }}
          />
          {smsSent && (
            <TextField
              margin='normal'
              required
              fullWidth
              label={t('auth.smsCode')}
              value={smsCode}
              onChange={(event) => {
                // Remove non-numerics and ensure size<=6
                const newSmsCode = event.target.value.replace(/\D/g, '').substring(0, 6);
                setErrMsg('');
                setSmsCode(newSmsCode);
              }}
              disabled={phone.length !== 10}
            />
          )}
          <Text size='note' color='error'>{errMsg}</Text>
          <Button
            fullWidth variant='contained' sx={{ mt: 40 }}
            disabled={(smsSent && smsCode.length !== 6) || (!smsSent && phone.length !== 10) || !!errMsg}
            onClick={handleSubmit}
          >
            {smsSent ? t('auth.confirmSms') : t('auth.sendSms')}
          </Button>
        </Box>

      </Box>
      <Box position='absolute' bottom={20} left={0} right={0} textAlign='center'>
        <Text size='paragraph' color='system' mt={64} sx={{ opacity: 0.7 }}>
          {'Copyright © '}
          <Link color='inherit' href='https://www.hellotaro.com/'>Taro LLC</Link>{' '}
          {new Date().getFullYear()}
        </Text>
      </Box>
    </Page>
  );
}
