export interface IProps {
  color: string
}

export default function TaroPassSvg({ color }: IProps) {
  return (
    <svg width='15' height='23' viewBox='0 0 15 23' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path fillRule='evenodd' clipRule='evenodd' d='M14.2697 2.00076C14.2697 0.727474 13.0956 -0.221632 11.8506 0.0451591L0.944768 2.38213C0.404742 2.49785 0.0607748 3.02943 0.176494 3.56946C0.206741 3.71061 0.265397 3.83836 0.345189 3.94802C0.12733 4.26826 0 4.65505 0 5.07158V20.687C0 21.7915 0.89543 22.687 2 22.687H13C14.1046 22.687 15 21.7915 15 20.687V5.07157C15 4.44909 14.7156 3.89302 14.2697 3.52621V2.00076ZM12.2697 3.07157L12.2697 2.00076L7.27257 3.07157H12.2697ZM2 5.07157H13V20.687H2L2 5.07157ZM7.49962 12.6097C8.30681 12.6097 8.96116 11.9554 8.96116 11.1482C8.96116 10.341 8.30681 9.68663 7.49962 9.68663C6.69244 9.68663 6.03809 10.341 6.03809 11.1482C6.03809 11.9554 6.69244 12.6097 7.49962 12.6097ZM7.49962 14.6097C9.41138 14.6097 10.9612 13.0599 10.9612 11.1482C10.9612 9.23641 9.41138 7.68663 7.49962 7.68663C5.58787 7.68663 4.03809 9.23641 4.03809 11.1482C4.03809 13.0599 5.58787 14.6097 7.49962 14.6097ZM4.61523 16.4942C4.06295 16.4942 3.61523 16.942 3.61523 17.4942C3.61523 18.0465 4.06295 18.4942 4.61523 18.4942H10.6729C11.2252 18.4942 11.6729 18.0465 11.6729 17.4942C11.6729 16.942 11.2252 16.4942 10.6729 16.4942H4.61523Z' fill={color} />
    </svg>
  );
}
